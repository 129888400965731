import { render, staticRenderFns } from "./FollowingList.vue?vue&type=template&id=c3d4579e&scoped=true&"
import script from "./FollowingList.vue?vue&type=script&setup=true&lang=ts&"
export * from "./FollowingList.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./FollowingList.vue?vue&type=style&index=0&id=c3d4579e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3d4579e",
  null
  
)

export default component.exports